exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-templates-about-page-js": () => import("./../../../src/templates/about-page.js" /* webpackChunkName: "component---src-templates-about-page-js" */),
  "component---src-templates-accessibility-page-js": () => import("./../../../src/templates/accessibility-page.js" /* webpackChunkName: "component---src-templates-accessibility-page-js" */),
  "component---src-templates-books-page-js": () => import("./../../../src/templates/books-page.js" /* webpackChunkName: "component---src-templates-books-page-js" */),
  "component---src-templates-contact-page-js": () => import("./../../../src/templates/contact-page.js" /* webpackChunkName: "component---src-templates-contact-page-js" */),
  "component---src-templates-governance-page-js": () => import("./../../../src/templates/governance-page.js" /* webpackChunkName: "component---src-templates-governance-page-js" */),
  "component---src-templates-journal-proposals-page-js": () => import("./../../../src/templates/journalProposals-page.js" /* webpackChunkName: "component---src-templates-journal-proposals-page-js" */),
  "component---src-templates-journals-page-js": () => import("./../../../src/templates/journals-page.js" /* webpackChunkName: "component---src-templates-journals-page-js" */),
  "component---src-templates-news-js": () => import("./../../../src/templates/news.js" /* webpackChunkName: "component---src-templates-news-js" */),
  "component---src-templates-our-supporters-page-js": () => import("./../../../src/templates/ourSupporters-page.js" /* webpackChunkName: "component---src-templates-our-supporters-page-js" */),
  "component---src-templates-publish-page-js": () => import("./../../../src/templates/publish-page.js" /* webpackChunkName: "component---src-templates-publish-page-js" */),
  "component---src-templates-research-integrity-page-js": () => import("./../../../src/templates/researchIntegrity-page.js" /* webpackChunkName: "component---src-templates-research-integrity-page-js" */),
  "component---src-templates-style-guide-page-js": () => import("./../../../src/templates/styleGuide-page.js" /* webpackChunkName: "component---src-templates-style-guide-page-js" */)
}

